import React from 'react';
import './ErrorFieldMessage.scss';
import ErrorFieldMessage from './ErrorFieldMessage';

function ErrorFieldMessageGroup(props) {
  return props.errors.map((error) =>
    <ErrorFieldMessage
      id={error.name}
      error={props.isShowError(error.name, props.errorFields)}
      message={error.message}
    />);
}

export default ErrorFieldMessageGroup;
