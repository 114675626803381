import { BaseHelper } from './BaseGraphQLAPIHelper';
import { convertPKToId } from '../utils';

export const getCampaignList = (
  afterCursor,
  type,
  reverse,
  filterName,
  ssoUid,
  isAvailable,
  others,
) => {
  let userFilter = '';
  if (ssoUid) {
    userFilter = `, isAvailable: true, customerSsoUid: "${ssoUid}"`;
  }

  if (!ssoUid && isAvailable && !others.isAllActive) {
    userFilter = `, isAvailable: true`;
  }

  // if (others.moreSearch) {
  //   userFilter += ', ' + others.moreSearch;
  // }

  if (others.status) {
    userFilter += `, isPublished: ${others.status}`;
  }
  if (others.expired) {
    userFilter += `, isExpired: ${others.expired}`;
  }
  if (others.message_channel) {
    userFilter += `, messageChannelsIn: "${others.message_channel}"`;
  }
  if (others.brand) {
    userFilter += `, brandIn: "${others.brand}"`;
  }
  if (others.active_period) {
    const activePeriod = others.active_period.split(',');
    userFilter += `, startDateGte: "${activePeriod[0]}", endDateLte: "${activePeriod[1]}"`;
  }
  if (others.visible_period) {
    const visiblePeriod = others.visible_period.split(',');
    userFilter += `, displayStartDateGte: "${visiblePeriod[0]}", displayEndDateLte: "${visiblePeriod[1]}"`;
  }
  if (others.target_customer) {
    if (others[others.target_customer])
      userFilter += `, ${others.target_customer}: "${others[others.target_customer]
        }"`;
  }
  let orderBy = reverse ? '-pk' : 'pk';
  if (others.is_featured) {
    orderBy = 'displayPriority';
    userFilter += `, isFeatured: true`;
  }
  if (others.is_whats_new) {
    orderBy = '-isExclusive,-lastModifiedDate';
    userFilter += `, displayInHome: true`;
  }
  if (others.sort) {
    orderBy = others.sort;
  }

  if (others.storeIn) {
    userFilter += `, storeIn: "${others.storeIn}"`;
  }
  if (others.earningRuleType) {
    userFilter += `, earningRuleType: "${others.earningRuleType}"`;
  }

  if (others.earningRuleTypeIn) {
    userFilter += `, earningRuleTypeIn: "${others.earningRuleTypeIn}"`;
  }
  if (others.merchantName) {
    userFilter += `, merchantName: "${others.merchantName}"`;
  }

  userFilter += `, first: ${others.isAll ? 100 : 20}`;

  const query = `{
      campaigns(after:"${afterCursor}", orderBy: "${orderBy}", nameIcontains:"${filterName}", type: "${type === 'AllTypes' ? '' : type
    }"${userFilter}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            pk
            id
            type
            name
            startDate
            endDate
            displayStartDate
            displayEndDate
            isPublished
            isExclusive
            lastModifiedDate
            publicationDate
            displayPriority
            couponDisplayPriority
            status
            coverPhoto
            brands {
              edges {
                  node {
                      pk
                      name
                  }
              }
            }
            numberOfCustomersVisible
            messageChannels
            earningCampaignTypeEarningRule {
              id
              pk
              name
              type
              habitTypeAllCampaignsToEarnRewards
              photoTakingTypePerHeadPerDayTotalQuantityLimit
            }
            translations {
              edges {
                node {
                  id
                  pk
                  name
                  language
                }
              }
            }
          }
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaign = (id) => {
  const query = `{
    campaign(id: "${id}") {
      id
      pk
      type
      name
      creationDate
      lastModifiedDate
      shortDescription
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      campaignTag
      startDate
      endDate
      displayStartDate
      displayEndDate
      displayInDiscover
      displayInHome
      homeSection
      isFeatured
      isExclusive
      isPublished
      isSponsored
      isSupported
      linkButtonType
      adLink
      publicationDate
      coverPhoto
      squareCoverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      language
      couponDisplayPriority
      displayPriority
      categories {
        edges {
          node{
            pk
            name
            id
          }
        }
      }
      targetedCustomerGroups {
        edges {
          node {
            pk
            name
            id
          }
        }
      }
      targetedSegments {
        edges {
          node{
            pk
            name
            id
          }
        }
      }
      translations {
        edges {
          node {
            id
            pk
            name
            shortDescription
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            campaignTag
            coverPhoto
            squareCoverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            language
          }
        }
      }
      couponCampaignTypeCouponTemplate {
          id
          pk
          name
      }
      couponCampaignTypeShouldShowCouponStock
      couponCampaignTypeOverallLimit
      couponCampaignTypePerHeadLimit
      couponCampaignTypeRequiredPoints
      earningCampaignTypeEarningRule {
          id
          pk
          name
          type
          habitTypePeriod
      }
      discount
      discountRequiredPoints
      discountStartDate
      discountEndDate
      discountTargetedCustomerGroups {
        edges {
          node{
            pk
            name
            id
          }
        }
      }
      discountTargetedCustomerSegments {
        edges {
          node{
            pk
            name
            id
          }
        }
      }
      brands {
        edges {
          node {
            pk
            name
          }
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignStores = (pk) => {
  const id = convertPKToId('CampaignNode', pk);
  const query = `{
    stores {
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
    campaign(id: "${id}") {
      id
      pk
      stores {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCampaignCategoryList = () => {
  const query = `{
    campaignCategories(first: 20) {
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaignCategory = (input) => {
  const query = `mutation CreateCampaignCategory($input: CreateCampaignCategoryInput!) {
    createCampaignCategory(input: $input) {
      node {
        id
        pk
        name
        displayPriority
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaign = (campaign) => {
  const query = `mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      node {
        id
        pk
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...campaign,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishCampaign = (input) => {
  const query = `mutation PublishCampaign($input: PublishCampaignInput!) {
    publishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishCampaign = (input) => {
  const query = `mutation UnpublishCampaign($input: UnpublishCampaignInput!) {
    unpublishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCampaigns = (ids) => {
  const query = `mutation DeleteCampaigns($input: DeleteCampaignsInput!) {
    deleteCampaigns(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCampaign = (input) => {
  const query = `mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      success
      node {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneEarningRule = (id) => {
  const query = `
  {
    earningRule(id: "${id}") {
      id
      pk
      name
      type
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      birthdayTypePeriodStartDate
      birthdayTypePeriodEndDate
      translations {
        edges {
          node {
            language
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
          }
        }
      }
      habitTypePeriod
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCoupon = (id) => {
  const query = `
  {
    couponTemplate(id: "${id}") {
      id
      pk
      name
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      coverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      translations {
        edges {
          node {
            language
            coverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
