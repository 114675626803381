import {
  getTransactions,
  getTransaction,
  approveTransaction,
  rejectTransaction,
  recallTransaction,
  deleteTransactions,
  updateCustomerMarkedLabel,
  updateTransactionJointBeauty,
  getRecentTransactions
} from '../services/TransactionRecordsAPIHelper';
import {
  TRANSACTION_RECORD_TYPE,
  TRANSACTION_EVENT_TYPE,
} from '../containers/record/records/TransactionList';
import {
  getDisplayDate,
  getCouponExpiryDate,
  formatDate,
} from '../utils/TimeFormatUtil';
import {
  createAction,
  convertCursorToNumber,
  formatNumberWithCommas,
} from '../utils';
import { loading } from './LoadingUtil';
import { LanguageConfig } from '../config/CustomEnums';
import { TRANSACTION_RELATED_TYPE } from './CreateTransactionModel';
import { GeneralPurchaseSubType } from './CreateEarningRulesModel';

const GeneralPurchasePaymentMethod = {
  CASH: "Cash",
  CREDIT_CARD: "Credit Card",
  OCTOPUS: "Octopus",
};

const getInitialState = () => ({
  transactionList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    {
      displayName: 'Name (preferred name)',
      fieldName: 'name',
      linked: false,
      orderField: 'customerFirstName',
    },
    {
      displayName: 'Membership ID',
      fieldName: 'membershipId',
      orderField: 'membershipId',
    },
    {
      displayName: 'Creation Date',
      fieldName: 'displayCreationDate',
      orderField: 'creationDate',
    },
    {
      displayName: 'Transaction Date',
      fieldName: 'displayTransactionDate',
      orderField: 'date',
    },
    { displayName: 'Merchant Name', fieldName: 'merchantName' },
    { displayName: 'Store', fieldName: 'storeName', orderField: 'store' },
    {
      displayName: 'Total Amount',
      fieldName: 'totalRewardAmount',
      orderField: 'totalRewardAmount',
    },
    { displayName: 'Transaction Type', fieldName: 'transactionDisplayType' },
    // { displayName: 'Online Event Type', fieldName: 'onlineEventType' },
    { displayName: 'Status', fieldName: 'displayOfflineEventType' },
    { displayName: 'Detected SKU', fieldName: 'skuPatternCanBeDetected' },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  recentTransctions: [],
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageTransactionList: [],
  selectedTransaction: {},
  updatingJointBeauty: false,
});

const parseTransactionDisplayType = (type, isOctopusTransaction) => {
  let displayType = '';
  switch (type) {
    case 'ONLINE':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_ONLINE;
      break;
    case 'OFFLINE_POS':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS;
      break;
    case 'OFFLINE_REWARD_CLAIM':
      displayType = isOctopusTransaction ? TRANSACTION_RECORD_TYPE.TYPE_OCTOPUS : TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM;
      break;
    case 'PHOTO_TAKING':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_PHOTO_TAKING;
      break;
    case 'QR_CODE_SCANNING':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_QR_CODE_SCANNING;
      break;
    case 'HABIT':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_HABIT;
      break;
    case 'WEEKLY_GOAL':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_WEEKLY_GOAL;
      break;
    case 'ACTIVE_TRACKING':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_ACTIVE_TRACKING;
      break;
    case 'ADMIN_CREATE':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_ADMIN_CREATE;
      break;
    case 'BARCODE':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_BARCODE;
      break;
    case 'REWARD_CODE':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_REWARD_CODE;
      break;
    default:
      break;
  }
  return displayType;
};

const parseRelatedTransactionType = (type) => {
  return TRANSACTION_RELATED_TYPE?.[type];
};

const parseTransactionTranslations = (data) => {
  const translations = {};
  translations[LanguageConfig.english] = {
    smallHeading: data?.smallHeading
  };
  const node = data?.translations?.edges?.map(item => item.node);
  (node || []).forEach(item => {
    if (item?.language) {
      translations[item.language] = item;
    }
  });

  console.log("@@124", translations)
  return translations;
};

const parseSkuPatternCanBeDetected = (skuPatternCanBeDetected) => {
  let value = '-';
  if (skuPatternCanBeDetected === true) {
    value = 'Yes';
  }
  if (skuPatternCanBeDetected === false) {
    value = 'No';
  }
  return value;
};

const parseEventType = (eventType) => {
  let displayEventType = '';
  switch (eventType) {
    case 'PENDING':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PENDING;
      break;
    case 'AUTHORIZED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_AUTHORIZED;
      break;
    case 'PAID':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PAID;
      break;
    case 'PARTIALLY_PAID':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_PAID;
      break;
    case 'REFUNDED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_REFUNDED;
      break;
    case 'PARTIALLY_REFUNDED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED;
      break;
    case 'VOIDED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_VOIDED;
      break;
    case 'WAITING_FOR_APPROVAL':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_WAITING;
      break;
    case 'APPROVED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_APPROVED;
      break;
    case 'RECALLED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_RECALLED;
      break;
    case 'REJECTED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_REJECTED;
      break;
    default:
      break;
  }
  return displayEventType;
};

export const parsePurchaseItems = (purchasedItems) => {
  const parsedPurchaseList = purchasedItems.map((item) => {
    const data = item.node;
    return {
      productName: data.name,
      sku: data.sku,
      category: data.category,
      brand: data.brand,
      quantity: data.quantity,
      value: data.value,
      displayValue: data.value
        ? `HK$ ${formatNumberWithCommas(data.value)}`
        : '',
      id: data.id,
      pk: data.pk,
    };
  });
  return parsedPurchaseList;
};

const getName = (customer) => {
  let name = '';
  if (!customer) {
    return '-';
  }
  if (customer.firstName) {
    name += customer?.firstName;
    name += ' '
  }
  if (customer.lastName) {
    name += customer?.lastName;
    name += ' '
  }
  if (customer.nickname) {
    name += `(${customer?.nickname})`;
  }
  if (!name) {
    return '-';
  }
  return name;
};

const parseMarkedLabel = (labels) => {
  return labels?.map(item => item?.node)?.filter(item => item?.amount);
};

const parseTransaction = (data) => {
  const purchasedItems = data.purchasedItems?.edges || [];
  const parsedPurchasedItems =
    purchasedItems.length > 0 ? parsePurchaseItems(purchasedItems) : [];
  const skuItems = parsedPurchasedItems.map((item) => item.sku);
  const customer = data.customer || null;
  const branchMapping = data.campaign?.earningCampaignTypeEarningRule?.branchMapping || [];
  return {
    ...data,
    pk: data.pk,
    id: data.id,
    nextTransactionPk: data.nextTransactionPk,
    membershipId: customer?.membershipId || '-',
    ssoUid: customer?.ssoUid,
    name: getName(customer),
    customerLabels: parseMarkedLabel(customer?.markedLabel?.edges),
    customerResetLabel: true,
    transactionType: data.transactionType,
    merchantName: data.merchantName,
    transactionDisplayType: parseTransactionDisplayType(data.transactionType, data.isOctopusTransaction),
    relatedTransactionType: parseRelatedTransactionType(data.relatedTransactionType),
    generalPurchaseSubType: data.generalPurchaseSubType,
    displayGeneralPurchasePaymentMethod: GeneralPurchasePaymentMethod?.[data?.generalPurchasePaymentMethod],
    displayGeneralPurchaseSubType: GeneralPurchaseSubType?.[data?.generalPurchaseSubType]?.label || GeneralPurchaseSubType.GREEN_DINING.label,
    rewardType: data.rewardType,
    rewardCarbonValue: data.rewardCarbonValue,
    rewardPointValue: data.rewardPointValue,
    translations: parseTransactionTranslations(data),
    storeName: data.store?.name,
    branch: branchMapping?.join(),
    creationDate: data.creationDate,
    displayCreationDate: getDisplayDate(data.creationDate),
    transactionDate: data.date,
    displayTransactionDate: getDisplayDate(data.date),
    transactionDetailDisplayDate: formatDate(
      data.date,
      'DD MMM yyyy (ddd),HH:mm a',
    ),
    transactionDetailCreationDate: formatDate(
      data.creationDate,
      'DD MMM yyyy (ddd),HH:mm a',
    ),
    duplicatedWithOclRecord: data.duplicatedWithOclRecord ? "Yes" : "No",
    duplicatedWithReceiptRecord: data.duplicatedWithReceiptRecord ? "Yes" : "No",
    oclDate: data.oclDate,
    oclDisplayDate: formatDate(
      data.oclDate,
      'DD MMM yyyy (ddd),HH:mm a',
    ),
    totalValue: data.totalValue,
    displayTotalValue: data.totalValue
      ? `HK$ ${formatNumberWithCommas(data.totalValue)}`
      : 'HK$ 0',
    totalRewardCarbon: data.totalRewardCarbon,
    totalRewardPoint: data.totalRewardPoint,
    totalRewardCoupon: data.totalRewardCoupon,
    onlineEventType: parseEventType(data.onlineEventType),
    offlineEventType: data.offlineEventType,
    displayOfflineEventType: parseEventType(data.offlineEventType),
    isOctopusTransaction: data.isOctopusTransaction ? "True" : "False",
    staffName: data.staffName,
    shippingFee: data.shippingFee
      ? `HK$ ${formatNumberWithCommas(data.shippingFee)}`
      : '',
    otherCharge: data.otherCharge
      ? `HK$ ${formatNumberWithCommas(data.otherCharge)}`
      : '',
    remarks: data.remarks,
    posInvoiceId: data.posInvoiceId,
    invoiceId: data.invoiceId,
    receiptImage: data.receiptImage,
    creditCardSlipImage: data.creditCardSlipImage,
    purchasedItems: parsedPurchasedItems,
    deleteDisplayName: `${getCouponExpiryDate(data.creationDate)} ${customer?.firstName
      } ${customer?.lastName} $${data.totalValue}`,
    customer: {
      pk: customer?.pk,
      name: getName(customer),
      ssoUid: customer?.ssoUid,
      firstName: customer?.firstName || '-',
      lastName: customer?.lastName || '-',
      nickname: customer?.nickname || '-',
      owner: getName(customer),
      membershipId: customer?.membershipId || '-',
    },
    store: {
      storeID: data.store?.id,
      storePK: data.store?.pk,
      storeName: data.store?.name,
    },
    createdDate: formatDate(data.creationDate),
    eventType:
      parseEventType(data.onlineEventType) ||
      parseEventType(data.offlineEventType) ||
      '-',
    displayStoreName: data.store?.name,
    transactionValue: data.totalValue,
    brand: data.store?.brand?.name,
    skuPatternCanBeDetected: parseSkuPatternCanBeDetected(
      data.skuPatternCanBeDetected,
    ),
    waterVolume: data.waterVolume,
    campaignName: data.campaign?.name,
    skuItems,
    sku: skuItems.join(', '),
    jointBeauty: data.jointBeauty || false,
  };
};

export default {
  namespace: 'transactions',
  state: getInitialState(),

  reducers: {
    clearData(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },

    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateSelectedTransaction(state, { payload }) {
      return {
        ...state,
        selectedTransaction: {
          ...state.selectedTransaction,
          ...payload
        },
      }
    },

    clearSelectedTransaction(state, { payload }) {
      return {
        ...state,
        selectedTransaction: {},
      };
    },
  },

  effects: {
    getCurrentPageTransactions: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = btoa(`arrayconnection:${(page - 1) * 20 - 1}`);
        }
        const serviceArgs = [getTransactions, afterCursor, payload];
        function* onSuccess(data) {
          const transactionData = data.transactions.edges;
          const pageInfo = data.transactions.pageInfo;
          const totalCount = data.transactions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const transactionList = transactionData.map((item) => {
            return parseTransaction(item.node);
          });
          yield put(
            createAction('updateState')({
              currentPageTransactionList: transactionList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],

    getRecentTransactions: [
      function* ({ payload }, { call, put }) {
        const { id, uid } = payload;
        console.log("getRecentTransactions: ", payload)
        const types = ["PHOTO_TAKING", "BARCODE"]
        const afterCursor = btoa(`arrayconnection:${id - 1}`);
        if (!uid) {
          return
        }
        const serviceArgs = [getRecentTransactions, afterCursor, { uid, types }];
        function* onSuccess(data) {
          const transactionData = data.transactions.edges;
          const transactionList = transactionData.map((item) => {
            return parseTransaction(item.node);
          });
          yield put({ type: "updateState", payload: { recentTransctions: transactionList } })
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],

    getTransaction: [
      function* ({ payload }, { call, put }) {
        const { transactionPK } = payload;
        yield put({ type: "updateState", payload: { recentTransctions: [] } })
        const afterActions = payload.afterActions || (() => { });
        const transactionID = btoa(`TransactionNode:${transactionPK}`);
        const serviceArgs = [getTransaction, transactionID];
        function* onSuccess(data) {
          const transactionData = data.transaction;
          const parsedTransaction = parseTransaction(transactionData)
          console.log("getTransacytction1: ", parsedTransaction)
          console.log("getTransacytction2: ", payload)
          if (payload?.withRecentTransactions && ["PHOTO_TAKING", "BARCODE"].includes(transactionData?.transactionType)) {
            yield put({ type: "getRecentTransactions", payload: { uid: parsedTransaction?.ssoUid, id: transactionPK } })
          }
          yield put(
            createAction('updateState')({
              selectedTransaction: parsedTransaction,
            }),
          );
          yield put(
            createAction('createTransaction/updateState')({
              transaction: parsedTransaction,
            }),
          );

          afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    approveTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK } = payload;
        const serviceArgs = [approveTransaction, transactionPK];
        function* onSuccess(data) {
          const afterActions = payload.afterActions || (() => { });
          if (data?.approveTransaction?.success) {
            yield afterActions();
          } else {
            yield put({
              type: 'navBars/updateState',
              payload: {
                saveDiscardToastShowing: {
                  value: true,
                  type: data?.approveTransaction?.error,
                },
              },
            });
          }
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    rejectTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK, rejectReason } = payload;
        const rejectReasonId = rejectReason?.value?.id;
        const serviceArgs = [rejectTransaction, transactionPK, rejectReasonId];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    recallTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK } = payload;
        const serviceArgs = [recallTransaction, transactionPK];
        function* onSuccess(data) {
          const afterActions = payload.afterActions || (() => { });
          if (data?.recallTransaction?.success) {
            yield afterActions();
          } else {
            const error = data?.recallTransaction?.error;
            // User didn't have enough carbon to recall.
            // User didn't have enough points to recall.
            // The reward has been used.
            let errorMessage = `Recall failed. ${error}`
            if (error === "The reward has been used.") {
              errorMessage = "Recall failed. The coupon has been used."
            }
            yield put({
              type: 'navBars/updateState',
              payload: {
                saveDiscardToastShowing: {
                  value: true,
                  type: errorMessage,
                },
              },
            });
          }
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    deleteTransaction: [
      function* ({ payload }, { put }) {
        const { ids } = payload;
        const serviceArgs = [deleteTransactions, ids];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    updateCustomerMarkedLabel: [
      function* ({ payload }, { put }) {
        yield put({
          type: 'updateSelectedTransaction',
          payload: {
            customerResetLabel: false,
          },
        })

        const inputBody = {
          customer: payload?.customer,
          markedLabels: payload?.markedLabels,
        };

        const serviceArgs = [updateCustomerMarkedLabel, inputBody];
        function* onSuccess() {
          yield put({
            type: 'updateSelectedTransaction',
            payload: {
              customerLabels: payload?.markedLabels,
              customerResetLabel: true,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    updateTransactionJointBeauty: [
      function* ({ payload }, { put }) {
        const inputBody = {
          id: payload?.id,
          jointBeauty: payload?.jointBeauty,
        };
        const serviceArgs = [updateTransactionJointBeauty, inputBody];
        function* onSuccess() {
          yield put({
            type: 'updateState',
            payload: {
              updatingJointBeauty: false
            },
          });
        }
        function* onError() {
          yield put({
            type: 'updateState',
            payload: {
              updatingJointBeauty: false
            },
          });
        }
        yield put({
          type: 'updateState',
          payload: {
            updatingJointBeauty: true
          },
        })
        yield loading(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
};
