import {
  getEarningRulesWithType,
  getEarningRuleMerchantNames,
  deleteEarningRules,
} from '../services/EarningRuleHelper';
import { assembleCouponTemplates } from './CouponUtil';
import { EarningRuleType, EarningRuleRewardType } from '../config/CustomEnums';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { convertCursorToNumber, convertNumberToCursor, delay } from '../utils';
import { getDisplayDate } from '../utils/TimeFormatUtil';
import { getFirstTrySubFix } from './CreateEarningRulesModel'
import { defaultOrder } from '../utils/OrderUtils';

const getInitialState = () => ({
  earningRuleList: [],
  earningRuleTypeList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedEarningRules: [],
  checkedList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Linked Campaign', fieldName: 'linkedCampaign' },
    { displayName: 'Reward Type', fieldName: 'rewardType' },
    {
      displayName: 'Create at',
      fieldName: 'displayCreated',
      orderField: 'creationDate',
    },
    {
      displayName: 'Last Modified',
      fieldName: 'displayModified',
      orderField: 'lastModifiedDate',
    },
  ],
  earningRuleExistsTypes: [],
});

const assembleEarningRules = (earningRules) =>
  earningRules.map((earningRule) => {
    const ruleNode = earningRule.node;
    const type = ruleNode.type;
    const couponTemplateData = {};
    switch (type) {
      case EarningRuleType.memberReferral:
        couponTemplateData.node =
          ruleNode.memberReferralTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.newMember:
        couponTemplateData.node =
          ruleNode.newMemberTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.birthday:
        couponTemplateData.node =
          ruleNode.birthdayTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.qrCodeScanning:
        couponTemplateData.node =
          ruleNode.qrCodeScanningTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.gpsCheckIn:
        couponTemplateData.node =
          ruleNode.gpsCheckInTypeCouponRewardTypeCouponTemplate;
        break;
      default:
        break;
    }

    const templates = [];
    if (couponTemplateData.node) {
      templates.push(couponTemplateData);
    }
    return {
      id: ruleNode.id,
      name: ruleNode.name,
      pk: ruleNode.pk,
      couponTemplate: assembleCouponTemplates(templates)[0] || {},
    };
  });

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const getRewardTypeString = (rewardType, earnRule, carbon, point, coupon) => {
  if (rewardType == EarningRuleRewardType.points) {
    const rewardPointValue = point;
    return `${rewardType}\r\n${rewardPointValue}pts ${carbon ? ', ' + carbon : ''
      }`;
  } else if (rewardType == 'COUPON') {
    const rewardCouponTemplate = coupon['name'];

    return `${rewardType}: ${rewardCouponTemplate} ${carbon ? ', ' + carbon : ''
      }`;
  }

  return null;
};

const getRewardType = (earnRule) => {
  const key = getKeyByValue(EarningRuleType, earnRule.type);
  const rewardType = earnRule[`${key}TypeRewardType`];
  const rewardStringList = [];
  if (earnRule.type === EarningRuleType.firstTry) {
    const { subcategoryID, fieldSubfix } = getFirstTrySubFix(earnRule)
    const rewardType = earnRule[`firstTryTypeRewardType${fieldSubfix}`]
    const coupons = earnRule[`firstTryTypeCouponRewardTypeCouponTemplate${fieldSubfix}`]
    const points = earnRule[`firstTryTypePointsRewardTypePoints${fieldSubfix}`]
    const reward = getRewardTypeString(
      rewardType,
      earnRule,
      '',
      points,
      coupons,
    );
    rewardStringList.push(reward);
    return rewardStringList.join(';\r\n');
  }

  if (
    earnRule.type === EarningRuleType.photoTaking ||
    earnRule.type === EarningRuleType.generalPurchase
  ) {
    const rewards =
      earnRule.type === EarningRuleType.photoTaking
        ? 'photoTakingTypeConversionRate'
        : 'generalPurchaseTypeRewards';
    const rewardStringList = earnRule[rewards]?.edges?.map((item) => {
      const carbon = item.node.carbonSavingQuantity
        ? `Carbon: ${item.node.carbonSavingQuantity}g`
        : '';
      return getRewardTypeString(
        item.node.rewardType,
        earnRule,
        carbon,
        item.node.rewardPoints,
        item.node.rewardCouponTemplate,
      );
    });
    return rewardStringList.join(';\r\n');
  }
  if (earnRule.type === EarningRuleType.activeTracking) {
    const reachMinimumRewards = getRewardTypeString(
      earnRule.activeTrackingTypeRewardTypeForReachMinimumDistance,
      earnRule,
      earnRule.activeTrackingTypeRewardCarbonForReachMinimumDistance,
      earnRule.activeTrackingTypePointsRewardTypePointsForReachMinimumDistance,
      earnRule.activeTrackingTypeCouponRewardTypeCouponTemplateForReachMinimumDistance,
    );
    const overMinimumRewards = getRewardTypeString(
      earnRule.activeTrackingTypeRewardTypeForOverMinimumDistance,
      earnRule,
      earnRule.activeTrackingTypeRewardCarbonForOverMinimumDistance,
      earnRule.activeTrackingTypePointsRewardTypePointsForOverMinimumDistance,
      earnRule.activeTrackingTypeCouponRewardTypeCouponTemplateForOverMinimumDistance,
    );
    return [reachMinimumRewards, overMinimumRewards].join(';\r\n');
  }

  const carbon =
    earnRule.rewardCarbon && earnRule.type !== EarningRuleType.habit
      ? `Carbon: ${earnRule.rewardCarbon}g`
      : '';

  return (
    getRewardTypeString(
      rewardType,
      earnRule,
      carbon,
      earnRule[`${key}TypePointsRewardTypePoints`],
      earnRule[`${key}TypeCouponRewardTypeCouponTemplate`],
    ) || rewardType
  );
};

const getLinkedCampaign = (earnRule) => {
  const campaigns = earnRule.campaigns.edges;
  let linkedCampaign = [];
  campaigns.map((campaign) => {
    linkedCampaign.push(campaign.node.name);
  });

  return linkedCampaign.join(', ');
};

const parseEarningRule = (item) => {
  return {
    ...item,
    linkedCampaign: getLinkedCampaign(item),
    rewardType: getRewardType(item),
    displayCreated: getDisplayDate(item.creationDate),
    displayModified: getDisplayDate(item.lastModifiedDate),
  };
};

export default {
  namespace: 'earningRuleList',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    assembleEarningRuleList(state, { payload }) {
      const { templateList, page } = payload;
      const earningRuleList = assembleEarningRules(templateList.edges);
      return {
        ...state,
        earningRuleList:
          page > 1
            ? [...state.earningRuleList, ...earningRuleList]
            : earningRuleList,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getEarningRuleListWithTypes: [
      function* ({ payload }, { call, all, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getEarningRulesWithType,
          pageCursor,
          payload.reverse,
          payload.type || 'all',
          payload.search,
          payload,
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const pageInfo = data?.earningRules?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.earningRules?.totalCount;
          yield all([
            put({
              type: 'updateState',
              payload: {
                earningRuleTypeList: data?.earningRules?.edges.map((item) =>
                  parseEarningRule(item.node),
                ),
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo.endCursor) + 1,
                },
                currentLastCursor,
                totalCount,
                totalPage: Math.ceil(totalCount / 20),
                earningRuleExistsTypes: data?.earningRules?.edges.map(
                  (item) => item.node.type,
                ),
              },
            }),
            put({
              type: 'assembleEarningRuleList',
              payload: { templateList: data?.earningRules, page: page },
            }),
          ]);
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getEarningRuleMerchantNames: [
      function* ({ payload }, { call, all, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getEarningRuleMerchantNames,
          pageCursor,
          payload.reverse,
          payload.type || 'all',
          payload.search,
          payload,
        ];
        function* onSuccess(data) {
          console.log('@@earning rule merchant names:: ', data?.earningRuleMerchantNames?.merchantNames);
          const originalMerchantNames = data?.earningRuleMerchantNames?.merchantNames || [];
          const merchantNames = originalMerchantNames.sort(defaultOrder)
          yield all([
            put({
              type: 'updateState',
              payload: {
                earningRuleMerchantNames: merchantNames
              },
            }),
          ]);
        }
        function* onFailed(data) {
          console.log('earning rule merchant names failed: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { select, put, all }) {
        const { checkedEarningRules } = yield select((state) => ({
          checkedEarningRules: state.earningRuleList.checkedList,
        }));

        let pks = [];
        let deleteCampaignIds = [];
        checkedEarningRules.forEach((item) => {
          pks.push(item.pk);

          item.campaigns.edges.forEach((campaign) => {
            deleteCampaignIds.push(campaign.node.pk);
          });
        });

        const serviceArgs = [deleteEarningRules, pks];
        const afterAction = payload.afterAction || (() => { });
        function* onSuccess(data) {
          console.log('@@153: ', data);
          yield put({
            type: 'earningRuleList/updateState',
            payload: { checkedList: [] },
          });
          yield delay(1000);
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
