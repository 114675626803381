import { LanguageConfig } from '../../../config/CustomEnums';
import { anchorElementWithId } from '../../../utils';
export const CampaignErrorHandleField = {
  generalName: {
    name: 'generalName',
    message: 'Please provide a campaign name.',
  },
  coverPhoto: {
    name: 'coverPhoto',
    message: 'Please provide a cover photo.',
  },
  squarePhoto: {
    name: 'squarePhoto',
    message: 'Please provide an square photo.',
  },
  shortDescription: {
    name: 'shortDescription',
    message: 'Please provide a short description.',
  },
  relatedBrand: {
    name: 'relatedBrand',
    message: 'Max select 2 brands',
  },
  campaignTag: {
    name: 'campaignTag',
    message: 'Please provide correct campaign tags.'
  },
  customerGroup: {
    name: 'customerGroup',
    message: 'Please provide a target customer group.',
  },
  segment: {
    name: 'customerGroup',
    message: 'Please provide a target segment.',
  },
  activeEndDate: {
    name: 'activeEndDate',
    message:
      'The end date & time of a campaign cannot be on or before the start date and time.',
  },
  visibleEndDate: {
    name: 'visibleEndDate',
    message:
      'The end date & time of a campaign cannot be on or before the start date and time.',
  },
  requiredPoints: {
    name: 'requiredPoints',
    message: 'Please provide a required points.',
  },
  requiredPointsWholeNumber: {
    name: 'requiredPointsWholeNumber',
    message: 'Required points should be a whole number.',
  },
  requiredPointsDiscountedRequired: {
    name: 'requiredPointsDiscountedRequired',
    message: 'Please provide a required points (discounted).',
  },
  requiredPointsDiscountedWholeNumber: {
    name: 'requiredPointsDiscountedWholeNumber',
    message: 'The discounted points should be a whole number.',
  },
  requiredPointsDiscountedAmount: {
    name: 'requiredPointsDiscountedAmount',
    message: 'The discounted points should be less than required points.',
  },
  discountStartEndDate: {
    name: 'discountStartEndDate',
    message:
      'The end date & time of the discount period cannot be on or before the start date and time.',
  },
  discountStartEndDateWithin: {
    name: 'discountStartEndDateWithin',
    message:
      'Please adjust the discount start date and end date within the campaign period.',
  },
  discountTargetedUser: {
    name: 'discountTargetedUser',
    message: 'Please provide the target user.',
  },
  discountTargetedUserGroup: {
    name: 'discountTargetedUserGroup',
    message: 'Please provide a target customer group or a target segment or both.',
  },
  couponDisplayPriority: {
    name: 'couponDisplayPriority',
    message: 'Minimum value is 1. The smaller value, the higher priority.',
  },
  adLink: {
    name: 'adLink',
    message: 'Please provide correct url.',
  },
  isShowError: (
    field,
    errors,
    language = LanguageConfig.english,
  ) => {
    const error =
      errors.indexOf(field) > -1 && language === LanguageConfig.english;
    return error;
  }
};

export const firstError = (id, errors, language = LanguageConfig.english) => {
  const firstError = errors[0];
  const error = id?.includes(firstError) && language === LanguageConfig.english;
  if (error) {
    setTimeout(() => {
      anchorElementWithId(firstError);
    }, 100);
  }
};

export const isShowError = (
  field,
  errors,
  language = LanguageConfig.english,
) => {
  const error =
    errors.indexOf(field) > -1 && language === LanguageConfig.english;
  return error;
};

export const isShowErrorGroup = (
  fields,
  errors,
  language = LanguageConfig.english,
) => {
  return fields.find((field) => isShowError(field.name, errors, language));
};
