import {
  createEarningRule,
  updateEarningRule,
  getOneEarningRule,
} from '../services/EarningRuleHelper';
import {
  EarningRuleType,
  EarningRuleTypeKey,
  LanguageConfig,
  CheckStatus,
  EarningRuleRewardType,
  GREEN_SHOPPING_CATEGORY_ID,
  PLANT_MILK_CATEGORY_ID
} from '../config/CustomEnums';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { apiWithResponseHandle } from './LoadingUtil';
import { emptyStringToNull, removeElementFromArray } from '../utils';
import {
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  convertPKToId,
} from '../utils';
import { EarningRuleErrorHandleField } from '../components/earning/EarningRuleHandleError';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';

export const GeneralPurchaseSubType = {
  GREEN_DINING: {
    label: 'Green Dining',
    value: 'GREEN_DINING'
  },
  GENERAL: {
    label: 'General',
    value: 'GENERAL'
  },
  GREEN_CAFE: {
    label: 'Plant Milk',
    value: 'GREEN_CAFE'
  },
  GREEN_SHOPS: {
    label: 'Green Shopping',
    value: 'GREEN_SHOPS'
  },
};

export const FirstTryType = {
  RECYCLING: "RECYCLING",
  GREEN_DINING: "GREEN_DINING",
  WATER_FILLING: "WATER_FILLING",
  GREEN_SHOPPING: "GREEN_SHOPPING",
  PLANT_MILK: "PLANT_MILK",
}

export const getFirstTrySubFix = (earnRule) => {
  const category = earnRule.firstTryCategory
  let subcategoryID = 2
  let fieldSubfix = 'Common'
  switch (category) {
    case FirstTryType.RECYCLING:
      subcategoryID = 1
      fieldSubfix = 'ForRecycling'
      break;
    case FirstTryType.GREEN_SHOPPING:
      subcategoryID = GREEN_SHOPPING_CATEGORY_ID
      break;
    case FirstTryType.WATER_FILLING:
      subcategoryID = 3
      fieldSubfix = "ForWaterFilling"
      break;
    case FirstTryType.PLANT_MILK:
      subcategoryID = PLANT_MILK_CATEGORY_ID
      break;
    case FirstTryType.GREEN_DINING:
      subcategoryID = 2
      fieldSubfix = "ForGreenDining"
      break;
    default:
      break;
  }
  return { subcategoryID, fieldSubfix }
}

export const GeneralPurchaseSubTypeOptions = Object.keys(GeneralPurchaseSubType).map(item => {
  return GeneralPurchaseSubType[item];
});

export const GeneralPurchaseSubTypeOptionsWithoutGeneralType = [
  {
    label: 'Green Dining',
    value: 'GREEN_DINING'
  },
  {
    label: 'Green Shopping',
    value: 'GREEN_SHOPS'
  },
  {
    label: 'Plant Milk',
    value: 'GREEN_CAFE'
  },
  {
    label: 'General Purchase',
    value: 'GENERAL'
  },
];

export function isSpecificEarningRule(type) {
  return (
    type === EarningRuleType.carbonImprove || type === EarningRuleType.firstTry
  );
}

const stepNames = ['Type', 'Content', 'Properties'];
const twoSteps = ['Content', 'Properties'];

const formatPhotoTakingRewards = (data) => {
  const rewards = {};
  for (let i = 0; i < data.length; i++) {
    const node = data[i].node;
    rewards[node.recyclingItem.pk] = {
      ...node,
      subcategories: node.recyclingItem.pk,
      rewardType: node.rewardType,
      quantity:
        node.rewardType === EarningRuleRewardType.points
          ? node.rewardPoints
          : node.rewardCouponQuantity, // template pouints
      coupons: node.rewardCouponTemplate,
      carbon: node.carbonSavingQuantity,
    };
  }

  return rewards;
};

const getMappingString = (originalList = []) => {
  let finalString = '';
  const listLength = originalList?.length;
  originalList?.forEach((item, index) => {
    finalString += `${item}${index + 1 === listLength ? '' : ','}`
  })
  return finalString;
};

const assembleMappList = (originalString = "") => {
  const finalList = originalString?.split(',')?.filter((item) => !!item);
  return finalList;
};

const getSkuPattern = (skuPatterns) => {
  let finalPattern = '';
  (skuPatterns || []).forEach((skuPattern) => {
    finalPattern += `${skuPattern};`;
  });
  return finalPattern;
};

const formatGeneralPurchaseReward = (data) => {
  const rewards = [];
  for (let i = 0; i < data.length; i++) {
    const node = data[i].node;
    const reward = {
      pk: node.pk,
      skuPattern: getSkuPattern(node.skuPatterns),
      rewardType: node.rewardType,
      quantity:
        node.rewardType === EarningRuleRewardType.points
          ? node.rewardPoints
          : node.rewardCouponQuantity,
      coupons: node.rewardCouponTemplate,
      carbon: node.carbonSavingQuantity,
    };
    rewards.push(reward);
  }

  return rewards;
};

const formatGeneralPurchaseExtraReward = (data = []) => {
  const rewards = []
  data.forEach(item => {
    const node = item.node;
    const reward = {
      pk: node?.pk,
      rewardType: node?.rewardType,
      quantity: node?.rewardType === EarningRuleRewardType.points
        ? node?.rewardPoints
        : node?.rewardCouponQuantity,
      coupons: node?.rewardCouponTemplate,
      carbon: node?.carbonSavingQuantity,
    }
    rewards.push(reward);
  })
  return rewards;
};

const formatFirstTryRewards = (earnRule) => {
  const rewards = {};
  const { subcategoryID, fieldSubfix } = getFirstTrySubFix(earnRule)
  const rewardType = earnRule[`firstTryTypeRewardType${fieldSubfix}`]
  const coupons = earnRule[`firstTryTypeCouponRewardTypeCouponTemplate${fieldSubfix}`]
  const points = earnRule[`firstTryTypePointsRewardTypePoints${fieldSubfix}`]
  const couponQuantity = earnRule[`firstTryTypeCouponRewardTypeQuantity${fieldSubfix}`]
  const quantity = rewardType === EarningRuleRewardType.points ? points : couponQuantity;
  rewards[subcategoryID] = {
    subcategories: subcategoryID,
    rewardType,
    quantity,
    coupons,
    type: EarningRuleType.firstTry,
  };
  return rewards;
};

const formatActiveTrackingRewards = (earnRule) => {
  const reachMinimumRewardType =
    earnRule.activeTrackingTypeRewardTypeForReachMinimumDistance;
  const overMinimumRewardType =
    earnRule.activeTrackingTypeRewardTypeForOverMinimumDistance;
  return {
    reachMinimum: {
      reachMinimumRewardType: reachMinimumRewardType,
      reachMinimumQuantity:
        reachMinimumRewardType === EarningRuleRewardType.points
          ? earnRule.activeTrackingTypePointsRewardTypePointsForReachMinimumDistance
          : earnRule.activeTrackingTypeCouponRewardTypeQuantityForReachMinimumDistance,
      reachMinimumCoupons:
        earnRule.activeTrackingTypeCouponRewardTypeCouponTemplateForReachMinimumDistance,
      reachMinimumCarbon:
        earnRule.activeTrackingTypeRewardCarbonForReachMinimumDistance,
    },
    overMinimum: {
      overMinimumRewardType: overMinimumRewardType,
      overMinimumQuantity:
        overMinimumRewardType === EarningRuleRewardType.points
          ? earnRule.activeTrackingTypePointsRewardTypePointsForOverMinimumDistance
          : earnRule.activeTrackingTypeCouponRewardTypeQuantityForOverMinimumDistance,
      overMinimumCoupons:
        earnRule.activeTrackingTypeCouponRewardTypeCouponTemplateForOverMinimumDistance,
      overMinimumCarbon:
        earnRule.activeTrackingTypeRewardCarbonForOverMinimumDistance,
    },
  };
};

const getRewards = (data) => {
  if (data.type === EarningRuleType.photoTaking) {
    return formatPhotoTakingRewards(data.photoTakingTypeConversionRate?.edges);
  }
  if (data.type === EarningRuleType.firstTry) {
    return formatFirstTryRewards(data);
  }
  if (data.type === EarningRuleType.activeTracking) {
    return formatActiveTrackingRewards(data);
  }
  return {};
};

const getInitialState = () => ({
  currentStep: 0,
  stepConfig: defaultStep(stepNames),
  earningRule: {},
  errorFields: [],
  formChanged: false,
  saved: -1,
  checked: CheckStatus.initOrNotChecked,
});

export default {
  namespace: 'createEarningRules',
  state: getInitialState(),
  reducers: {
    changeStepConfig(state, { payload }) {
      const changedStepConfig = defaultStep(twoSteps);
      const stateStepConfig = state.stepConfig;
      if (stateStepConfig?.length === changedStepConfig.length) {
        return { ...state };
      }
      return { ...state, payload, stepConfig: defaultStep(twoSteps) };
    },

    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    stepChange(state, { payload }) {
      const stateStepConfig = state.stepConfig;
      const earningRuleType = state.earningRule.type;
      const currentStep = payload.step;
      const isBack = payload.isBack;
      const notCarbonImproveOrFirstTry = !isSpecificEarningRule(
        earningRuleType,
      );
      const data = getObjectFromSessionStorage('tempEarningRule'); // payload.data;
      console.log('stepChange:', data);

      const isSave = payload.isSave;
      let errorFields = [];
      let checked = CheckStatus.initOrNotChecked;
      if (!isBack) {
        const requiredFieldsConfig = [
          ['generalName'],
          {
            general_purchase: [
              'merchantName',
              // 'receiptNumberPattern',
              'skuRewards',
            ],
            gps_check_in: ['latitude', 'longitude', 'radius'],
            birthday: ['birthStart', 'birthEnd'],
            reward: ['rewardType', 'coupons', 'quantity', 'rewardTypeX'],
            carbon_reward: ['rewards'],
            new_member: [],
            member_referral: [],
            qr_code_scanning: ['qrCodes'],
            // monthly_achievement: ['monthlyGoal'],
            // weekly_achievement: ['increasePercentage'],
            habit: ['habitCampaigns', 'goal'],
            photo_taking: [
              'photoTakingStores',
              'perItemAmountLimit',
              'perHeadTotalAmountLimit',
            ],
            water_filling: [],
            carbon_improve: [],
            first_try: [],
            active_tracking: ['minDistance', 'maxDistance', 'rewards'],
            activationCode: ['qrCodes'],
          },
        ];
        if (notCarbonImproveOrFirstTry) {
          requiredFieldsConfig.unshift([]);
        }
        const requireFieldsStep = notCarbonImproveOrFirstTry ? 2 : 1;
        const requiredFields = [];
        if (currentStep != requireFieldsStep) {
          requiredFields.push(...requiredFieldsConfig[currentStep]);
        } else {
          const isRecyling = [
            EarningRuleType.photoTaking,
            EarningRuleType.firstTry,
          ].includes(data.type);
          if (data.type === EarningRuleType.activeTracking) {
            requiredFields.push(
              ...requiredFieldsConfig[currentStep]['active_tracking'],
            );
          } else if (isRecyling) {
            requiredFields.push(
              ...requiredFieldsConfig[currentStep]['carbon_reward'],
            );
          } else if (data.type != EarningRuleType.generalPurchase) {
            requiredFields.push(...requiredFieldsConfig[currentStep]['reward']);
          } else if (data.type === EarningRuleType.generalPurchase) {
            requiredFields.push(
              ...requiredFieldsConfig[currentStep]['general_purchase'],
            );
          }
        }

        requiredFields.forEach((field, index) => {
          let need_skip = false;

          switch (field) {
            case 'coupons':
              if (data['rewardType'] !== EarningRuleRewardType.coupons) {
                need_skip = true;
              }
              break;
            case 'rewardTypeX':
              if (
                data['type'] !== EarningRuleType.generalPurchase ||
                (data['rewardType'] !== EarningRuleRewardType.points &&
                  data['type'] === EarningRuleType.generalPurchase)
              ) {
                need_skip = true;
              }
              break;
            case 'photoTakingStores':
              need_skip = data.isAllRecyclingStores;
              break;

            case 'minDistance':
            case 'maxDistance':
              if ('' === data[field] || !(data[field] >= 0)) {
                errorFields.push(EarningRuleErrorHandleField[field].name);
              } else {
                need_skip = true;
              }
              break;
            case 'rewards':
              if (data.type === EarningRuleType.activeTracking) {
                if (!(data.rewards?.overMinimum?.overMinimumQuantity >= 0)) {
                  errorFields.push(
                    EarningRuleErrorHandleField.overMinimumQuantity.name,
                  );
                }
                if (!(data.rewards?.reachMinimum?.reachMinimumQuantity >= 0)) {
                  errorFields.push(
                    EarningRuleErrorHandleField.reachMinimumQuantity.name,
                  );
                }
                if (
                  data.rewards?.overMinimum?.overMinimumRewardType ===
                  EarningRuleRewardType.coupons &&
                  !data.rewards?.overMinimum?.overMinimumCoupons
                ) {
                  errorFields.push(
                    EarningRuleErrorHandleField.overMinimumCoupons.name,
                  );
                }
                if (
                  data.rewards?.reachMinimum?.reachMinimumRewardType ===
                  EarningRuleRewardType.coupons &&
                  !data.rewards?.reachMinimum?.reachMinimumCoupons
                ) {
                  errorFields.push(
                    EarningRuleErrorHandleField.reachMinimumCoupons.name,
                  );
                }
                break;
              }
              if (
                Object.values(data['rewards'] || []).filter((item) => {
                  if (!item) return false;
                  return (
                    (item.rewardType === EarningRuleRewardType.coupons &&
                      item.coupons &&
                      item.quantity) ||
                    (item.rewardType === EarningRuleRewardType.points &&
                      item.quantity)
                  );
                }).length <= 0
              ) {
                errorFields.push(EarningRuleErrorHandleField[field].name);
                need_skip = true;
              }
              break;
            default:
              break;
          }
          if (need_skip) {
            return;
          }
          const contentStep = notCarbonImproveOrFirstTry ? 1 : 0;
          const value =
            currentStep === contentStep ? data[LanguageConfig.english] : data;
          if (!value || !value[field] || value[field].length <= 0) {
            errorFields.push(EarningRuleErrorHandleField[field].name);
            return;
          }

          switch (field) {
            case 'perHeadTotalAmountLimit':
              if (
                parseInt(data[field]) < parseInt(data['perItemAmountLimit'])
              ) {
                errorFields.push(EarningRuleErrorHandleField[field].name);
              }
              break;
            case 'merchantName':
              if (!data[field]) {
                errorFields.push(EarningRuleErrorHandleField[field].name);
              }
              break;
            // case 'receiptNumberPattern':
            //   if (!data[field]) {
            //     errorFields.push(EarningRuleErrorHandleField[field].name);
            //   }
            //   break;
            case 'skuRewards':
              if (!data[field] || !data[field]?.length) {
                errorFields.push(EarningRuleErrorHandleField[field].name);
              } else {
                for (let i = 0; i < data[field].length; i++) {
                  const skuReward = data[field][i];
                  const noPattern = !skuReward?.skuPattern?.length;
                  console.log('noPattern:', noPattern);
                  const pointTypeAndNoPoint =
                    skuReward.rewardType === EarningRuleRewardType.points &&
                    !skuReward.quantity;
                  const couponTypeAndNoCouponPoint =
                    skuReward.rewardType === EarningRuleRewardType.coupons &&
                    (!skuReward.coupons?.pk || !skuReward.quantity);
                  if (
                    noPattern ||
                    pointTypeAndNoPoint ||
                    couponTypeAndNoCouponPoint ||
                    (skuReward?.quantity && parseInt(skuReward?.quantity) <= 0) ||
                    (skuReward?.carbon && parseInt(skuReward?.carbon) <= 0)
                  ) {
                    errorFields.push(EarningRuleErrorHandleField[field].name);
                  }
                }
                const filteredPatterns = data[field]?.filter(
                  (item) => !!item?.skuPattern,
                );
                const patterns = filteredPatterns?.map((sku) => sku.skuPattern);
                const patternsSet = new Set(patterns);
                const hasDuplicatePatterns =
                  patterns?.length != [...patternsSet]?.length;
                if (
                  hasDuplicatePatterns &&
                  !errorFields?.includes('skuRewards')
                ) {
                  errorFields.push(
                    EarningRuleErrorHandleField.skuPatternDuplicate.name,
                  );
                }
              }
              break;
            case 'latitude':
            case 'longitude':
              const dataField = data[field] + '';
              if (dataField.split('.')[0].length > 3) {
                errorFields.push(
                  EarningRuleErrorHandleField[`${field}Limit`].name,
                );
              }
              break;
            default:
              break;
          }

          if (errorFields.length > 0) {
            return;
          }
        });

        if (data.type === EarningRuleType.generalPurchase && currentStep === 2) {
          if (!data.merchantMapping && !data.branchMapping) {
            errorFields.push(EarningRuleErrorHandleField.merchantMapping.name);
            errorFields.push(EarningRuleErrorHandleField.branchMapping.name);
          }
          if (data.octopusMerchantId && !data.octopusExtraRewards?.length) {
            errorFields.push(EarningRuleErrorHandleField.octopusExtraRewards.name);
          }
          if (data.octopusExtraRewards?.length) {
            for (let i = 0; i < data.octopusExtraRewards.length; i++) {
              const extraReward = data.octopusExtraRewards[i];
              const couponTypeAndNoCouponPoint =
                extraReward.rewardType === EarningRuleRewardType.coupons &&
                (!extraReward.coupons?.pk || !extraReward.quantity);
              if (
                couponTypeAndNoCouponPoint ||
                (extraReward?.quantity && parseInt(extraReward?.quantity) < 0) ||
                (extraReward?.carbon && parseInt(extraReward?.carbon) < 0)
              ) {
                errorFields.push(EarningRuleErrorHandleField.octopusExtraRewards.name);
              }
            }
          }
        }

        checked =
          errorFields.length <= 0
            ? CheckStatus.checkedWithSuccess
            : CheckStatus.checkedWithFail;
        if (
          checked &&
          data.type === EarningRuleType.activeTracking &&
          parseInt(data.maxDistance) < parseInt(data.minDistance)
        ) {
          errorFields.push(EarningRuleErrorHandleField.wrongDistance.name);
          checked = false;
        }
      }

      const checkInValid = checked === CheckStatus.checkedWithFail;

      const stepConfig = getNewStepConfig(
        currentStep,
        stateStepConfig,
        checkInValid,
        isBack,
      );
      let nextStep = currentStep;
      if (!checkInValid) {
        nextStep = isBack
          ? currentStep - 1
          : isSave
            ? currentStep
            : currentStep + 1;
      }
      console.log(
        '@@106: ',
        currentStep,
        '->',
        nextStep,
        checkInValid,
        isSave,
        isBack,
        state.checked,
        errorFields,
      );

      let earningRule = {};
      if (!isBack) {
        earningRule = getObjectFromSessionStorage('tempEarningRule');
      }

      return {
        ...state,
        currentStep: nextStep,
        stepConfig,
        errorFields,
        checked:
          nextStep !== currentStep ? CheckStatus.initOrNotChecked : checked,
        earningRule: {
          ...state.earningRule,
          ...earningRule,
        },
      };
    },

    loadEarningRuleFromCookie(state, { payload }) {
      const tempEarningRule = getObjectFromSessionStorage('tempEarningRule');
      const defaultData = payload ? payload.data.earningRule : {};
      let earningRule = defaultData;
      if (!tempEarningRule) {
        let formatData = defaultData;
        let commonData = {};

        if (Object.keys(defaultData).length > 0) {
          const type = EarningRuleTypeKey[defaultData.type];
          formatData = {
            id: defaultData.id,
            pk: defaultData.pk,
            type: defaultData.type,
            carbon: defaultData.rewardCarbon,
            removedReward: [],
            removedExtraReward: [],
            en: {
              generalName: defaultData.name,
              instructionSectionTitle: defaultData.instructionSectionTitle,
              instructionSectionContent: defaultData.instructionSectionContent,
              detailSectionTitle: defaultData.detailSectionTitle,
              detailSectionContent: defaultData.detailSectionContent,
            },

            merchantName: defaultData.generalPurchaseTypeMerchantName,
            generalPurchaseSubType: defaultData.generalPurchaseSubType,
            merchantMapping: getMappingString(defaultData.merchantMapping),
            branchMapping: getMappingString(defaultData.branchMapping),
            octopusMerchantId: defaultData.octopusMerchantId,
            // receiptNumberPattern:
            //   defaultData.generalPurchaseTypeReceiptNumberPattern,
            selectedStores: defaultData.generalPurchaseTypeLimitedToStores.edges?.map(
              (item, index) => item.node,
            ),
            referralType: defaultData.memberReferralTypeBeneficiary,
            maxInvitees: defaultData.memberReferralTypeLimit,

            latitude: defaultData.gpsCheckInTypeLatitude,
            longitude: defaultData.gpsCheckInTypeLongitude,
            radius: defaultData.gpsCheckInTypeRadiusInMeter,

            birthStart: defaultData.birthdayTypePeriodStartDate,
            birthEnd: defaultData.birthdayTypePeriodEndDate,

            qrCodes: defaultData.qrCodeScanningTypeQrCodes,

            monthlyGoal: defaultData.monthlyCarbonSavingGoal,
            increasePercentage:
              defaultData.weeklyImprovementPercentageThanPrevious * 100,

            rewardType: defaultData[`${type}TypeRewardType`],
            quantity:
              defaultData[`${type}TypeRewardType`] ===
                EarningRuleRewardType.points
                ? defaultData[`${type}TypePointsRewardTypePoints`]
                : defaultData[`${type}TypeCouponRewardTypeQuantity`], // template pouints
            coupons: defaultData[`${type}TypeCouponRewardTypeCouponTemplate`],

            goal: defaultData.habitTypeCarbonSavingGoal,
            isAllCampaign: defaultData.habitTypeAllCampaignsToEarnRewards,
            habitCampaigns: {
              label: defaultData.habitTypeCampaignToEarnRewards?.name,
              value: defaultData.habitTypeCampaignToEarnRewards,
            },
            habitPerHeadLimit:
              defaultData.habitTypeCarbonSavingTimesPerHeadPerDayLimit,
            habitTimePeriod: defaultData.habitTypePeriod,

            isAllRecyclingStores:
              defaultData.photoTakingTypeAllRecyclingStoresToEarnRewards,
            photoTakingStores: defaultData.photoTakingTypeSpecificStoresToEarnRewards.edges.map(
              (item) => item.node,
            ),
            perItemAmountLimit: defaultData.photoTakingTypePerItemQuantityLimit,
            perHeadTotalAmountLimit:
              defaultData.photoTakingTypePerHeadPerDayTotalQuantityLimit,

            rewards: getRewards(defaultData),
            skuRewards: formatGeneralPurchaseReward(
              defaultData.generalPurchaseTypeRewards?.edges,
            ),
            octopusExtraRewards: formatGeneralPurchaseExtraReward(
              defaultData.octopusExtraRewards?.edges,
            ),

            machineId: defaultData.waterFillingTypeMachineId,

            minDistance: defaultData.activeTrackingTypeMinimumDistance,
            maxDistance: defaultData.activeTrackingTypeMaximumDistance,
          };

          if (defaultData.translations.edges?.length > 0) {
            for (let i = 0; i < defaultData.translations.edges.length; i++) {
              const node = defaultData.translations.edges[i].node;

              formatData[node.language] = {
                id: node.pk,
              };

              formatData[node.language]['instructionSectionTitle'] =
                node.instructionSectionTitle;
              formatData[node.language]['instructionSectionContent'] =
                node.instructionSectionContent;
              formatData[node.language]['detailSectionTitle'] =
                node.detailSectionTitle;
              formatData[node.language]['detailSectionContent'] =
                node.detailSectionContent;
            }
          }

          if (!('zh-Hant' in formatData)) {
            formatData['zh-Hant'] = {};
            formatData['zh-Hant']['language'] = 'zh-Hant';
            formatData['zh-Hant']['instructionSectionTitle'] = '';
            formatData['zh-Hant']['instructionSectionContent'] = '';
            formatData['zh-Hant']['detailSectionTitle'] = '';
            formatData['zh-Hant']['detailSectionContent'] = '';
          }

          if (!('zh-Hans' in formatData)) {
            formatData['zh-Hans'] = {};
            formatData['zh-Hans']['language'] = 'zh-Hans';
            formatData['zh-Hans']['instructionSectionTitle'] = '';
            formatData['zh-Hans']['instructionSectionContent'] = '';
            formatData['zh-Hans']['detailSectionTitle'] = '';
            formatData['zh-Hans']['detailSectionContent'] = '';
          }

          switch (defaultData.type) {
            case EarningRuleType.gpsCheckIn:
            case EarningRuleType.qrCodeScanning:
            case EarningRuleType.waterFilling:
            case EarningRuleType.photoTaking:
              commonData = {
                overallLimit: defaultData[`${type}TypeOverallLimit`],
                perHeadLimit: defaultData[`${type}TypePerHeadLimit`],
                periodicLimit: defaultData[`${type}TypePeriodicLimit`],
                periodicLimitDays:
                  defaultData[`${type}TypePeriodicLimitEffectiveNumberOfDays`],
                perHeadPeriodicLimit:
                  defaultData[`${type}TypePerHeadPeriodicLimit`],
                perHeadPeriodicLimitDays:
                  defaultData[
                  `${type}TypePerHeadPeriodicLimitEffectiveNumberOfDays`
                  ],
              };

              break;

            case EarningRuleType.memberReferral:
              commonData = {};
              break;
            case EarningRuleType.birthday:
              commonData = {};
              break;
            case EarningRuleType.activationCode:
              commonData = {
                qrCodes: defaultData['codes'],
                overallLimit: defaultData['overallLimit'],
                perHeadLimit: defaultData['perHeadLimit'],
                periodicLimit: defaultData['periodicLimit'],
                periodicLimitDays: defaultData['periodicLimitEffectiveNumberOfDays'],
                perHeadPeriodicLimit: defaultData['perHeadPeriodicLimit'],
                perHeadPeriodicLimitDays: defaultData['perHeadPeriodicLimitEffectiveNumberOfDays'],
                rewardType: defaultData['rewardType'],
                quantity:
                  defaultData['rewardType'] === EarningRuleRewardType.points
                    ? defaultData['rewardPoints']
                    : defaultData['rewardCouponQuantity'],
                coupons: defaultData['rewardCouponTemplate'],
              };
              break;
            default:
              break;
          }
        }

        earningRule = { ...formatData, ...commonData };
      }

      if (!defaultData.pk) {
        earningRule = tempEarningRule || {};
        delete earningRule.id;
        delete earningRule.pk;
      }

      saveToSessionStorage('tempEarningRule', earningRule);
      return {
        ...state,
        earningRule,
      };
    },
    removeEarningRuleFromCookie(state, { payload }) {
      removeFromSessionStorage('tempEarningRule');

      return {
        ...state,
        formChanged: false,
      };
    },

    changeVals(state, { payload }) {
      console.log('@@138: vals changed', payload);
      let tempEarningRule = getObjectFromSessionStorage('tempEarningRule');
      const payloadLanguage = payload?.language;

      if (!payload?.vals) {
        return { ...state, formChanged: true };
      }

      let data = { ...payload.vals };
      if (payloadLanguage && tempEarningRule && data) {
        data = {
          [payloadLanguage]: {
            ...tempEarningRule[payloadLanguage],
            ...payload.vals,
          },
        };
      }
      if (payload.groupKey) {
        switch (tempEarningRule.type) {
          case EarningRuleType.generalPurchase:
            if (payload.groupKey?.includes("SKU")) {
              const skuId = parseInt(payload.groupKey?.replace("SKU", ""));
              const tempRewards = tempEarningRule.skuRewards || [];
              if (tempRewards?.length < skuId) {
                data.skuRewards = [...tempRewards, payload.vals];
              } else {
                data.skuRewards = tempRewards.map((item, index) => {
                  if (index + 1 === skuId) {
                    return { ...item, ...payload.vals };
                  }
                  return item;
                });
              }
            }
            if (payload.groupKey?.includes("octopusReward")) {
              const rewardId = parseInt(payload.groupKey?.replace("octopusReward", ""));
              const tempRewards = tempEarningRule.octopusExtraRewards || [];
              if (tempRewards?.length < rewardId) {
                data.octopusExtraRewards = [...tempRewards, payload.vals];
              } else {
                data.octopusExtraRewards = tempRewards.map((item, index) => {
                  if (index + 1 === rewardId) {
                    return { ...item, ...payload.vals };
                  }
                  return item;
                });
              }
            }
            break;
          default:
            data.rewards = tempEarningRule.rewards || {};
            data.rewards[payload.groupKey] = {
              ...(tempEarningRule.rewards
                ? tempEarningRule.rewards[payload.groupKey]
                : {}),
              ...payload.vals,
            };
            data.removedReward = removeElementFromArray(
              tempEarningRule.removedReward || [],
              payload.groupKey,
            );
            break;
        }
      }

      tempEarningRule = { ...tempEarningRule, ...data };
      saveToSessionStorage('tempEarningRule', tempEarningRule);

      return {
        ...state,
        formChanged: true,
      };
    },

    removeVals(state, { payload }) {
      let tempEarningRule = getObjectFromSessionStorage('tempEarningRule');
      if (payload.rewardsGroupKey) {
        tempEarningRule = {
          ...tempEarningRule,
          removedReward: [
            ...(tempEarningRule.removedReward || []),
            payload.rewardsGroupKey,
          ],
        };
        if (tempEarningRule.rewards) {
          delete tempEarningRule.rewards[payload.rewardsGroupKey];
        }

        saveToSessionStorage('tempEarningRule', tempEarningRule);
      }
      if (payload.extraRewardsGroupKey) {
        tempEarningRule = {
          ...tempEarningRule,
          removedExtraReward: [
            ...(tempEarningRule.removedExtraReward || []),
            payload.extraRewardsGroupKey,
          ],
        };
        saveToSessionStorage('tempEarningRule', tempEarningRule);
      }

      return {
        ...state,
        formChanged: true,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getOneEarningRule: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [
          getOneEarningRule,
          convertPKToId('EarningRuleNode', payload.id),
        ];
        function* onSuccess(data) {
          //save to state.earningRule
          removeFromSessionStorage('tempEarningRule');
          yield put({
            type: 'loadEarningRuleFromCookie',
            payload: { data },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateEarningRule: [
      function* ({ payload }, { call, all, put }) {
        const tempEarningRule = getObjectFromSessionStorage('tempEarningRule');
        console.log("tempEarningRule:", tempEarningRule)
        if (!tempEarningRule?.type) {
          return;
        }
        const key = EarningRuleTypeKey[tempEarningRule.type];

        let data = {
          name: tempEarningRule.en.generalName,
          type: tempEarningRule.type,
          instructionSectionTitle: tempEarningRule.en.instructionSectionTitle,
          instructionSectionContent:
            tempEarningRule.en.instructionSectionContent,
          detailSectionTitle: tempEarningRule.en.detailSectionTitle,
          detailSectionContent: tempEarningRule.en.detailSectionContent,
          translations: [
            LanguageConfig.traditionalChinese,
            LanguageConfig.simplifiedChinese,
          ].map((lang) => {
            delete tempEarningRule[lang].generalName;
            return {
              ...tempEarningRule[lang],
              language: lang,
            };
          }),
        };
        if (data.type !== EarningRuleType.generalPurchase) {
          data.rewardCarbon = tempEarningRule.carbon || null;
        }
        let rewardData = {};
        if (
          ![
            EarningRuleType.photoTaking,
            EarningRuleType.firstTry,
            EarningRuleType.generalPurchase,
            EarningRuleType.activeTracking,
            EarningRuleType.activationCode,
          ].includes(data.type)
        ) {
          data[`${key}TypeRewardType`] = tempEarningRule.rewardType;

          if (tempEarningRule.rewardType === EarningRuleRewardType.points) {
            rewardData[`${key}TypePointsRewardTypePoints`] =
              tempEarningRule.quantity;
          } else {
            rewardData[`${key}TypeCouponRewardTypeCouponTemplate`] =
              tempEarningRule.coupons.pk;
            rewardData[`${key}TypeCouponRewardTypeQuantity`] =
              tempEarningRule.quantity;
          }
        } else if (data.type === EarningRuleType.activationCode) {
          data['rewardType'] = tempEarningRule.rewardType;

          if (tempEarningRule.rewardType === EarningRuleRewardType.points) {
            rewardData['rewardPoints'] =
              tempEarningRule.quantity;
          } else {
            rewardData['rewardCouponTemplate'] =
              tempEarningRule.coupons.pk;
            rewardData['rewardCouponQuantity'] =
              tempEarningRule.quantity;
          }
        }

        let limitValues = {};
        if (
          [
            EarningRuleType.gpsCheckIn,
            EarningRuleType.qrCodeScanning,
            EarningRuleType.photoTaking,
            EarningRuleType.waterFilling,
          ].includes(data.type)
        ) {
          limitValues = {
            [`${EarningRuleTypeKey[data.type]}TypeOverallLimit`]:
              parseInt(tempEarningRule.overallLimit) || null,
            [`${EarningRuleTypeKey[data.type]}TypePerHeadLimit`]:
              parseInt(tempEarningRule.perHeadLimit) || null,
            [`${EarningRuleTypeKey[data.type]}TypePeriodicLimit`]:
              parseInt(tempEarningRule.periodicLimit) || null,
            [`${EarningRuleTypeKey[data.type]
              }TypePeriodicLimitEffectiveNumberOfDays`]:
              parseInt(tempEarningRule.periodicLimitDays) || null,
            [`${EarningRuleTypeKey[data.type]}TypePerHeadPeriodicLimit`]:
              parseInt(tempEarningRule.perHeadPeriodicLimit) || null,
            [`${EarningRuleTypeKey[data.type]
              }TypePerHeadPeriodicLimitEffectiveNumberOfDays`]:
              parseInt(tempEarningRule.perHeadPeriodicLimitDays) || null,
          };
        }
        if (data.type === EarningRuleType.activationCode) {
          limitValues = {
            ['overallLimit']:
              parseInt(tempEarningRule.overallLimit) || null,
            ['perHeadLimit']:
              parseInt(tempEarningRule.perHeadLimit) || null,
            ['periodicLimit']:
              parseInt(tempEarningRule.periodicLimit) || null,
            ['periodicLimitEffectiveNumberOfDays']:
              parseInt(tempEarningRule.periodicLimitDays) || null,
            ['perHeadPeriodicLimit']:
              parseInt(tempEarningRule.perHeadPeriodicLimit) || null,
            ['perHeadPeriodicLimitEffectiveNumberOfDays']:
              parseInt(tempEarningRule.perHeadPeriodicLimitDays) || null,
          };
        };

        let values = {};
        switch (tempEarningRule.type) {
          case EarningRuleType.generalPurchase:
            const generalPurchaseTypeRewards = [];
            for (let i = 0; i < tempEarningRule.skuRewards?.length; i++) {
              const reward = tempEarningRule.skuRewards[i];
              const skuPatterns = reward?.skuPattern
                ?.split(';')
                ?.filter((item) => !!item);
              console.log('skuPatterns:', reward);
              generalPurchaseTypeRewards.push({
                id: reward.pk,
                skuPatterns,
                rewardType: reward.rewardType,
                ...(reward.rewardType === EarningRuleRewardType.points
                  ? { rewardPoints: reward.quantity || null }
                  : {
                    rewardCouponTemplate: reward.coupons.pk || null,
                    rewardCouponQuantity: reward.quantity || null,
                  }),
                carbonSavingQuantity: reward.carbon || null,
              });
            }
            const octopusExtraRewards = [];
            for (let i = 0; i < tempEarningRule.octopusExtraRewards?.length; i++) {
              const reward = tempEarningRule.octopusExtraRewards[i];
              octopusExtraRewards.push({
                id: reward.pk,
                rewardType: reward.rewardType,
                ...(reward.rewardType === EarningRuleRewardType.points
                  ? { rewardPoints: reward.quantity || 0 }
                  : {
                    rewardCouponTemplate: reward.coupons?.pk || null,
                    rewardCouponQuantity: reward.quantity || null,
                  }),
                carbonSavingQuantity: reward.carbon || null,
              });
            }
            values = {
              // generalPurchaseTypeReceiptNumberPattern:
              //   tempEarningRule.receiptNumberPattern,
              generalPurchaseTypeMerchantName: tempEarningRule.merchantName,
              merchantMapping: assembleMappList(tempEarningRule.merchantMapping) || [],
              branchMapping: assembleMappList(tempEarningRule.branchMapping) || [],
              octopusMerchantId: tempEarningRule.octopusMerchantId || '',
              generalPurchaseSubType: tempEarningRule.generalPurchaseSubType || GeneralPurchaseSubType.GREEN_DINING.value,
              generalPurchaseTypeRewards,
              octopusExtraRewards,
            };
            if (tempEarningRule.pk) {
              values['generalPurchaseRewardToDelete'] = tempEarningRule.removedReward;
              values['octopusExtraRewardsToDelete'] = tempEarningRule.removedExtraReward;
            }
            break;
          case EarningRuleType.gpsCheckIn:
            values = {
              gpsCheckInTypeLatitude: tempEarningRule.latitude,
              gpsCheckInTypeLongitude: tempEarningRule.longitude,
              gpsCheckInTypeRadiusInMeter: tempEarningRule.radius,
              ...limitValues,
              ...rewardData,
            };
            break;
          case EarningRuleType.memberReferral:
            values = {
              memberReferralTypeBeneficiary: tempEarningRule.referralType?.toUpperCase(),
              memberReferralTypeLimit: tempEarningRule.maxInvitees,
              ...rewardData,
            };
            break;
          case EarningRuleType.birthday:
            values = {
              birthdayTypePeriodStartDate: formatDate(
                new Date(
                  tempEarningRule.birthStart.split('-')[0],
                  tempEarningRule.birthStart.split('-')[1] - 1,
                  1,
                ),
                TimeFormater.yearMonthDay,
              ),
              birthdayTypePeriodEndDate: formatDate(
                new Date(
                  tempEarningRule.birthEnd.split('-')[0],
                  tempEarningRule.birthEnd.split('-')[1],
                  0,
                ),
                TimeFormater.yearMonthDay,
              ),
              ...rewardData,
            };
            break;
          case EarningRuleType.qrCodeScanning:
            values = {
              qrCodeScanningTypeQrCodes: tempEarningRule.qrCodes,
              ...limitValues,
              ...rewardData,
            };
            break;
          case EarningRuleType.activationCode:
            values = {
              codes: tempEarningRule.qrCodes,
              ...limitValues,
              ...rewardData,
            };
            break;
          case EarningRuleType.habit:
            values = {
              habitTypeCarbonSavingGoal: tempEarningRule.goal,
              habitTypeAllCampaignsToEarnRewards: tempEarningRule.isAllCampaign,
              habitTypeCarbonSavingTimesPerHeadPerDayLimit:
                tempEarningRule.habitPerHeadLimit,
              habitTypePeriod: tempEarningRule.habitTimePeriod,
              ...rewardData,
            };
            if (!tempEarningRule.isAllCampaign) {
              values.habitTypeCampaignToEarnRewards =
                tempEarningRule.habitCampaigns?.value?.pk;
            }

            break;
          case EarningRuleType.firstTry:
            const firstTryRewardSubcategories = Object.keys(
              tempEarningRule.rewards || {},
            );

            const recyclingTypeKeyId = '1';
            const greenDiningTypeKeyId = '2';
            const waterFillingTypeKeyId = '3';
            const greenShoppingID = GREEN_SHOPPING_CATEGORY_ID;
            const plantMilkID = PLANT_MILK_CATEGORY_ID;
            const firstTryIDs = [recyclingTypeKeyId, greenDiningTypeKeyId, waterFillingTypeKeyId, greenShoppingID, plantMilkID]
            const emptyReward = {
              rewardType: null,
              quantity: null,
              coupons: { pk: null },
            };
            const firstTryRewards = {};
            firstTryRewards[
              recyclingTypeKeyId
            ] = firstTryRewardSubcategories?.includes(recyclingTypeKeyId)
                ? tempEarningRule.rewards[recyclingTypeKeyId]
                : emptyReward;

            firstTryRewards[
              greenDiningTypeKeyId
            ] = firstTryRewardSubcategories?.includes(greenDiningTypeKeyId)
                ? tempEarningRule.rewards[greenDiningTypeKeyId]
                : emptyReward;

            firstTryRewards[
              waterFillingTypeKeyId
            ] = firstTryRewardSubcategories?.includes(waterFillingTypeKeyId)
                ? tempEarningRule.rewards[waterFillingTypeKeyId]
                : emptyReward;

            firstTryRewards[
              greenShoppingID
            ] = firstTryRewardSubcategories?.includes(greenShoppingID)
                ? tempEarningRule.rewards[greenShoppingID]
                : emptyReward;

            firstTryRewards[
              plantMilkID
            ] = firstTryRewardSubcategories?.includes(plantMilkID)
                ? tempEarningRule.rewards[plantMilkID]
                : emptyReward;
            firstTryIDs.forEach(id => {
              const reward = firstTryRewards[id];
              const isPoints =
                reward.rewardType === EarningRuleRewardType.points;
              let subcategoryType = '';
              switch (id) {
                case 0:
                  subcategoryType = 'ForRecycling';
                  break;
                case 1:
                  subcategoryType = 'ForGreenDining';
                  break;
                case 2:
                  subcategoryType = 'ForWaterFilling';
                  break;
                default:
                  subcategoryType = "Common"
                  break;
              }

              if (subcategoryType) {
                const firstTryRewardTypeCommon = `firstTryTypeRewardType${subcategoryType}`;
                const firstTryRewardPointsCommon = `firstTryTypePointsRewardTypePoints${subcategoryType}`;
                const firstTryRewardQuantityCommon = `firstTryTypeCouponRewardTypeQuantity${subcategoryType}`;
                const firstTryRewardCouponCommon = `firstTryTypeCouponRewardTypeCouponTemplate${subcategoryType}`;

                values[firstTryRewardTypeCommon] = reward.rewardType;
                values[firstTryRewardPointsCommon] = isPoints
                  ? reward.quantity
                  : null;
                values[firstTryRewardQuantityCommon] = isPoints
                  ? null
                  : reward.quantity;
                values[firstTryRewardCouponCommon] = isPoints
                  ? null
                  : reward?.coupons?.pk;
              }
            })
            break;
          case EarningRuleType.photoTaking:
            const subcategories = Object.keys(tempEarningRule.rewards || {});
            let photoTakingTypeConversionRate = [];
            for (let i = 0; i < subcategories.length; i++) {
              const reward = tempEarningRule.rewards[subcategories[i]];

              if (
                (reward.rewardType === EarningRuleRewardType.points &&
                  reward.quantity) ||
                (reward.rewardType === EarningRuleRewardType.coupons &&
                  reward.quantity &&
                  reward.coupons)
              ) {
                photoTakingTypeConversionRate.push({
                  id: reward.pk,
                  recyclingItem: subcategories[i],
                  rewardType: reward.rewardType,
                  ...(reward.rewardType === EarningRuleRewardType.points
                    ? { rewardPoints: reward.quantity }
                    : {
                      rewardCouponTemplate: reward.coupons.pk,
                      rewardCouponQuantity: reward.quantity,
                    }),
                  carbonSavingQuantity: parseInt(reward.carbon) || 0,
                });
              }
            }

            values = {
              photoTakingTypeAllRecyclingStoresToEarnRewards:
                tempEarningRule.isAllRecyclingStores,
              photoTakingTypeConversionRate,
              photoTakingTypePerItemQuantityLimit:
                tempEarningRule.perItemAmountLimit,
              photoTakingTypePerHeadPerDayTotalQuantityLimit:
                tempEarningRule.perHeadTotalAmountLimit,

              ...limitValues,
            };
            if (!tempEarningRule.isAllRecyclingStores) {
              values[
                'photoTakingTypeSpecificStoresToEarnRewards'
              ] = tempEarningRule.photoTakingStores.map((item) => item.pk);
            }

            if (tempEarningRule.pk) {
              values['conversionRateToDelete'] = tempEarningRule.removedReward;
            }

            break;
          case EarningRuleType.waterFilling:
            values = {
              waterFillingTypeMachineId: tempEarningRule.machineId,
              ...limitValues,
              ...rewardData,
            };
            break;
          case EarningRuleType.activeTracking:
            const reachMinimum = tempEarningRule.rewards.reachMinimum || {};
            const reachMinimumRewardIsPoints =
              reachMinimum.reachMinimumRewardType ===
              EarningRuleRewardType.points;
            const overMinimum = tempEarningRule.rewards.overMinimum || {};
            const overMinimumRewardIsPoints =
              overMinimum.overMinimumRewardType ===
              EarningRuleRewardType.points;
            values = {
              activeTrackingTypeMinimumDistance: tempEarningRule.minDistance,
              activeTrackingTypeMaximumDistance: tempEarningRule.maxDistance,

              activeTrackingTypeRewardCarbonForReachMinimumDistance: emptyStringToNull(
                reachMinimum.reachMinimumCarbon,
              ),
              activeTrackingTypeRewardTypeForReachMinimumDistance:
                reachMinimum.reachMinimumRewardType,
              activeTrackingTypePointsRewardTypePointsForReachMinimumDistance: reachMinimumRewardIsPoints
                ? reachMinimum.reachMinimumQuantity
                : null,
              activeTrackingTypeCouponRewardTypeCouponTemplateForReachMinimumDistance: reachMinimumRewardIsPoints
                ? null
                : reachMinimum.reachMinimumCoupons?.pk,
              activeTrackingTypeCouponRewardTypeQuantityForReachMinimumDistance: reachMinimumRewardIsPoints
                ? null
                : reachMinimum.reachMinimumQuantity,

              activeTrackingTypeRewardCarbonForOverMinimumDistance: emptyStringToNull(
                overMinimum.overMinimumCarbon,
              ),
              activeTrackingTypeRewardTypeForOverMinimumDistance:
                overMinimum.overMinimumRewardType,
              activeTrackingTypePointsRewardTypePointsForOverMinimumDistance: overMinimumRewardIsPoints
                ? overMinimum.overMinimumQuantity
                : null,
              activeTrackingTypeCouponRewardTypeCouponTemplateForOverMinimumDistance: overMinimumRewardIsPoints
                ? null
                : overMinimum.overMinimumCoupons?.pk,
              activeTrackingTypeCouponRewardTypeQuantityForOverMinimumDistance: overMinimumRewardIsPoints
                ? null
                : overMinimum.overMinimumQuantity,
            };

            break;
          default:
            values = {
              ...rewardData,
            };
            break;
        }

        let serviceArgs = [createEarningRule, { ...data, ...values }];
        if (tempEarningRule.pk) {
          serviceArgs = [
            updateEarningRule,
            { id: tempEarningRule.pk, ...data, ...values },
          ];
        }
        console.log('@@493: ', { ...data, ...values });
        function* onSuccess(data) {
          if (
            ('createEarningRule' in data && data.createEarningRule.errors) ||
            ('updateEarningRule' in data && data.updateEarningRule.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                saved: 0,
                formChanged: false,
                checked: CheckStatus.initOrNotChecked,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                saved: 1,
                formChanged: false,
                earningRule: { pk: data.createEarningRule?.node?.pk },
                checked: CheckStatus.initOrNotChecked,
              },
            });
          }

          yield put({ type: 'removeEarningRuleFromCookie' });
          // removeFromSessionStorage('tempEarningRule');
        }

        function* onFail(errors) {
          yield put({
            type: 'updateState',
            payload: {
              saved: 0,
              formChanged: false,
              checked: CheckStatus.initOrNotChecked,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFail, onFail);
      },
      { type: 'takeLatest' },
    ],
  },
};
